<!--专项维修资金收支-->
<template>
  <page-container title="物业收支情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">

            <a-form-model-item label="公司/项目" prop="userdepid">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="queryParams.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="年份" prop="collect_time">
              <a-select v-model="queryParams.collect_time">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
              </a-select>
            </a-form-model-item>

            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="类型" prop="timeNode">
              <a-select v-model="queryParams.timeNode">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(item, index) in timeNodeTypeList" :key="index" :value="parseInt(item.dickey,10)">
                  {{ item.dicvalue }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="上传时间" prop="time_interval">
              <a-range-picker v-model="queryParams.time_interval">
              </a-range-picker>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">专项维修资金收支情况列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <span slot="file_path" slot-scope="value, record" class="file_path-view">
              <a-tag v-if="record.file_path!==''" class="text" @click.stop="downloadAnnexClick(record.file_path)">
                {{ record.file_path.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>
          <span slot="confirm_status" slot-scope="text" class="confirm_status-view">
            <span class="dot" :class="{'red': text===0}"></span>
            <span class="text">{{ text === 1 ? '已确认' : '未确认' }}</span>
          </span>
          <span slot="time_node" slot-scope="text" class="propertyWorkSpecials_people_type-view">
            <span class="text">{{ subtypeMap[text] }}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                 <a-menu-item v-if="record.confirm_status===0&&(userInfo.rolename==='项目管理员'||userInfo.useraccount==='admin')" :key="'confirm-'+record.id">确认</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改物业收支页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:12}" :wrapper-col="{span:10}">
        <a-form-model-item label="公司/项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'"  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="类型" prop="timeNode">
          <a-select :disabled="modalType==='detail'" v-model="modalForm.timeNode">

            <a-select-option v-for="(item, index) in timeNodeTypeList" :key="index" :value="parseInt(item.dickey,10)">
              {{ item.dicvalue }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="年份" prop="collect_time">
          <a-select :disabled="modalType==='detail'"  v-model="modalForm.collect_time">

            <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="专项维修资金收支记录表附件" prop="file_path">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadSpecialMaintenanceFundsData" action="/upload" :file-list="recordList" @change="specialMaintenanceFundsUploaded"
                    accept=".doc,.docx,.pdf,.xls,.xlsx" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceSpecialMaintenanceFunds,
  getSpecialMaintenanceFundsListByCondition,
  deleteOneSpecialMaintenanceFunds,
  editSpecialMaintenanceFunds, updateConfirmStatusByFilepath, updateSpecialMaintenanceFundsConfirmStatusById
} from 'A/performance'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {mapGetters} from "vuex";
import {userdep} from "V/dataAnalysis/performance/minins/userdep";
import {getDictByDicType} from "A/xtpz";

export default {
  name: 'specialMaintenanceFunds',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      recordList:[],
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '专项维修资金收支',
          path: ''
        }
      ],
      queryParams: {
        timeNode:'',
        collect_time:'',
        time_interval: [],
        userdepid:'',
        selectDeptList:[]
      },
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '年份',
          dataIndex: 'collect_time',
          key: 'collect_time'
        },
        {
          title: '类型',
          dataIndex: 'time_node',
          key: 'time_node',
          scopedSlots: {customRender: 'time_node'}
        },
        {
          title: '上传时间',
          dataIndex: 'create_time',
          key: 'create_time'
        },
        {
          title: '收支情况记录表附件',
          dataIndex: 'file_path',
          key: 'file_path',
          scopedSlots: { customRender: 'file_path' }
        },
        {
          title: '确认状态',
          dataIndex: 'confirm_status',
          key: 'confirm_status',
          scopedSlots: {customRender: 'confirm_status'}
        },
        {
          title: '确认时间',
          dataIndex: 'confirm_time',
          key: 'confirm_time',
        },
        {
          title: '确认人',
          dataIndex: 'confirm_person',
          key: 'confirm_person',
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        confirm_status:0,
        timeNode:'',
        collect_time:'',
        file_path: '',
        userdepid:"",
        selectDeptList:[],
      },
      modalRules: {
        timeNode: [{required: true, message: '请选择统计类型'}],
        //collect_time: [{required: true, message: '请选择数据上传时间'}],
        file_path: [{required: true, message: '请上传记录表附件'}],
        userdepid: [{required: true, message: '请选择公司/项目'}]
      },
      selectedSpecialMaintenanceFunds:null,
      timeNodeTypeList:[],
      yearOptions: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadSpecialMaintenanceFundsData']),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
    //查询专项维修资金收支单时间
    query_begin_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[0]) {
        return moment(this.queryParams.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    query_end_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[1]) {
        return moment(this.queryParams.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    //添加专项维修资金收支单时间
    add_collect_time() {
      if (this.modalForm.collect_time) {
        return moment(this.modalForm.collect_time).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    //查询
    query_collect_time() {
      if (this.queryParams.collect_time) {
        return moment(this.queryParams.collect_time).format('YYYY');
      } else {
        return ''
      }
    },
    subtypeMap() {
      let result = {};
      this.timeNodeTypeList.forEach(item => {
        result[item.dickey]=item.dicvalue
      })
      return result;
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        // this.queryParams.userdepid = val.join('|')
        // this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    this.init();
  },
  methods: {
    init() {
      this.getYearOptions();
      this.getTimeNodeType();
      this.getTableData();
    },
    resetQueryParams() {
      this.queryParams.selectDeptList=[]
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=4; i>-1; i--) {
        this.yearOptions.push(currentYear-i+'');
      }
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      // let userdepid = this.queryParams.userdepid;
      // if(userdepid.indexOf('|') > -1) {
      //   userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      // }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        begin_time: this.query_begin_time===''?'':this.query_begin_time+" 00:00:00",
        end_time:this.query_end_time===''?'':this.query_end_time+" 23:59:59",
        time_node:this.queryParams.timeNode,
        //userdep_id:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
        //userdepid:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,

        collect_time_begin: this.query_collect_time===''?null:this.query_collect_time+'-01-01',
        collect_time_end: this.query_collect_time===''?null:this.query_collect_time+'-12-31',
      };
      console.log(params)
      getSpecialMaintenanceFundsListByCondition(params).then(res => {
        this.tableLoading = false;
        console.log(res.item)
        if (res && res.returncode === '0') {
          this.tableData = res.item?res.item.map(item => ({
            ...item,
            create_time:moment(item.create_time).format('YYYY-MM-DD HH:mm:ss'),
            confirm_time:item.confirm_time?moment(item.confirm_time).format('YYYY-MM-DD HH:mm:ss'):'',
            collect_time:item.collect_time?moment(item.collect_time).format('YYYY年'):''
          })):[]
          this.pagination.total = res.count;
        }
        console.log(this.tableData)
      }).catch(err => {
        console.log(err)
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    //获取时间节点类别
    getTimeNodeType(){
      let params = {
        dictype: '85'
      };
      getDictByDicType(params).then(res => {
        if (res && res.returncode === '0') {
          this.timeNodeTypeList = res.item ? res.item : []
        }
      })
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id=0
        this.modalForm.confirm_status=0
        this.modalForm.timeNode=''
        this.modalForm.collect_time=''
        this.modalForm.file_path= ''
        this.modalForm.userdepid= ''
        this.modalForm.selectDeptList= []
        this.recordList = []
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true;
        this.modalForm.id=record.id
        this.modalForm.confirm_status=record.confirm_status
        this.modalForm.timeNode=record.time_node
        this.modalForm.collect_time=record.collect_time
        this.modalForm.file_path= record.file_path
        this.modalForm.userdepid = record.userdep_id

        const file_name = record.file_path.split("/").pop();
        this.recordList = [{
          uid: 'filenameuid',
          name: file_name,
          status: 'done',
          url: record.file_path,
        }];
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(specialMaintenanceFunds_id) {
      if (specialMaintenanceFunds_id) {
        let params = {
          specialMaintenanceFunds_id
        };
        deleteOneSpecialMaintenanceFunds(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑物业收支单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let params = {
        collect_time: this.add_collect_time,
        file_path:this.modalForm.file_path,
        time_node:this.modalForm.timeNode,
        confirm_status:this.modalForm.confirm_status,
        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        console.log(params)
        addPerformanceSpecialMaintenanceFunds(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editSpecialMaintenanceFunds(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedSpecialMaintenanceFunds = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedSpecialMaintenanceFunds)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedSpecialMaintenanceFunds)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedSpecialMaintenanceFunds)
      } else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedSpecialMaintenanceFunds)
      }else if ('confirm' == type) {
        this.confirmConfirm(id, this.selectedSpecialMaintenanceFunds)
      }
    },
    handleClickRow(record,index){
      console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
    //上传服务标准的附件
    specialMaintenanceFundsUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      //console.log(fileList)
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.file_path = file.response.urlPath;
        }
        return file;
      });
      this.recordList = fileList;
    },
    downloadAnnexClick(path){
      if(path!==''){
        const pageUrl = window.location.origin;
        const name = path.split("/").pop();
        const download_path=pageUrl+"/"+path;
        let a = document.createElement('a');
        a.href = download_path;
        a.download = name;
        a.click();
      }else{
        this.$message.error("文件为空！");
      }
    },
    beforeUpload(file){
      return new Promise((resolve, reject) => {
        const isJpgOrPng =
            file.type === "application/pdf" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/msword"||
            file.type === "application/vnd.ms-excel"||
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isJpgOrPng) {
          this.$message.error("上传文件格式只能是doc/docx/pdf/xls/xlsx");
          return reject(false);
        }
        const isLimit = file.size / 1024 / 1024 < 5;
        if (!isLimit) {
          this.$message.error("上传文件大小不能超过 5MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
    confirmConfirm(value, record) {
      this.$confirm('确认发布数据吗？', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.goToConfirm(record.id,record);
      }).catch(() => {
      });
    },
    goToConfirm(id,record) {
      //本次确认要连同app端的文件一起确认
      if (id) {
        let params = {
          id,
          confirm_status: "1",
          confirm_person: this.userInfo.username
        };
        updateSpecialMaintenanceFundsConfirmStatusById(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('确认成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '确认失败');
          }
        })
      }
    },

  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.file_path-view {
  display: flex;
  align-items: center;
  .text {
    display: inline-block;
    margin-left: 7px;
    //border: 1px solid black;
    color: #57c921;
    &.red {
      color: #e70c0c;
    }
  }
}
.confirm_status-view {
  display: flex;
  align-items: center;

  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #65ff18;
    border-radius: 50%;

    &.red {
      background-color: #e70c0c;
    }
  }

  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
</style>